import React from "react";

const Spinner = () => {
	return (
		<div className="ui__spinner">
			<span className="ui__spinner__icon"></span>
			<span className={'ui__spinner__text'}>Загрузка...</span>
		</div>
	);
};

export default Spinner;
