import React from "react";
import {Link, useLocation} from "react-router-dom";

const NoMatch = (props) => {
    const location = useLocation();

    return <div className={"page center"}>
        <h3 className={"text-dark"}>
            404: Страницы <code>{location.pathname}</code> не существует
        </h3>
        <Link to={"/"} className={"text-warning text-decoration-none"}>
            Вернуться на главную
        </Link>
    </div>
};

export default NoMatch;
