import {
    BrowserRouter as Router,
    Navigate,
    Route,
    Routes,
} from 'react-router-dom'
import React, {lazy, Suspense, useEffect} from 'react'
import ProvideAuth, {useAuth, useProvideAuth} from './context/provideAuth'
import NoMatch from './pages/NoMatch'
import {useDispatch, useSelector} from 'react-redux'
import request from './tools/ajaxManager'
import Spinner from "./component/ui-kit/Spinner";

const PublicRoutes = lazy(() => import('./routes/Public'))
const PrivateRoutes = lazy(() => import('./routes/Private'))

function App() {
    const {user} = useSelector((state) => state)
    const dispatch = useDispatch()
    const auth = useProvideAuth();

    useEffect(() => {
        if (user && user.token) {
            request(
                `/user/`,
                'GET',
                {},
                {
                    'YT-AUTH-TOKEN': `YourTar ${user.token}`,
                },
                function (response) {
                    if (response.user) {
                        dispatch({type: 'UPDATE', payload: response.user})
                        auth.login(response.user)
                    }
                },
                function (error, code) {
                    if (code !== 401) alert(error.message)
                    else dispatch({type: 'LOGOUT'})
                }
            )
        }
    }, [])

    return <div className={'App'}>
        <ProvideAuth>
            <Router>
                <Suspense fallback={<Spinner/>}>
                    <Routes>
                            <Route path='/cabinet/*' element={user.user ? <PrivateRoutes/> : <Navigate to='/'/>}/>

                            <Route path='/*' element={<PublicRoutes/>}/>

                            <Route path='*' element={<NoMatch to='/'/>}/>
                    </Routes>
                </Suspense>
            </Router>
        </ProvideAuth>
    </div>;
}

export default App;
