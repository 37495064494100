const defaultState = { maps: [], layers: [] }

const mapBackingReducer = function (state = defaultState, action) {
  switch (action.type) {
    case 'ADD_ALL_BACKINGS':
      return { ...action.payload }
    case 'CHANGE_MAP':
      return {
        ...state,
        maps: state.maps.map((map) => (map.id === action.payload.id ? { ...action.payload } : map)),
      }
    case 'CHANGE_LAYER':
      return {
        ...state,
        layers: state.layers.map((layer) =>
          layer.id === action.payload.id ? { ...action.payload } : layer
        ),
      }
    default:
      return state
  }
}

export default mapBackingReducer
