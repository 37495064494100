import { combineReducers } from 'redux'
import { createStore } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import persistCombineReducers from 'redux-persist/es/persistCombineReducers'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import detectDeviceReducer from './detectDeviceReducer'
import mapBackingReducer from './mapBackingReducer'
import userReducer from './userReducer'

const rootReducer = {
  user: userReducer,
  isMobile: detectDeviceReducer,
  mapBackings: mapBackingReducer,
}

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistCombineReducers(persistConfig, rootReducer)

let store = createStore(persistedReducer)
let persistor = persistStore(store)
let projectStorage = { store, persistor }

export default projectStorage
