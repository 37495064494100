import request from "../tools/ajaxManager";

const defaultState = { user: null, token: null }

const userReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'LOGIN':
      return Object.assign({}, action.payload)
    case 'LOGOUT':
      request(
          `/security/logout` + (action.payload && action.payload.all === true ? '?all=true' : ''),
          'GET',
          {},
          {
            'YT-AUTH-TOKEN': `YourTar ${state.token}`,
          },
          function (response) {},
          function (error) {
            alert(error.message)
          }
      )
      return defaultState
    case 'UPDATE':
      state.user = action.payload;
      return state
    default:
      return state
  }
}

export default userReducer
